import { NextSeo } from "next-seo";

export interface SEOProps {
  title: string;
  description: string;
  image?: string;
  publishedTime?: string;
  modifiedTime?: string;
}

const DEFAULT_OG_IMAGE =
  "https://res.cloudinary.com/dl7p14ddo/image/upload/v1728683958/vital/og-image-shadow_dlyf8i.jpg";

const SEO = ({
  title,
  description,
  image,
  publishedTime,
  modifiedTime,
}: SEOProps) => {
  const ogImage = image ?? DEFAULT_OG_IMAGE;

  return (
    <NextSeo
      title={title + " | Vital"}
      description={description}
      twitter={{
        cardType: "summary_large_image",
        handle: "@VitalScoreAI",
      }}
      openGraph={{
        type: image ? "article" : "website",
        siteName: "Vital",
        url: "https://www.vitalscore.ai",
        title: title + " | Vital",
        description: description,
        locale: "en_US",
        images: [
          {
            url: ogImage,
            width: 1200,
            height: 630,
            alt: title,
            type: "image/jpg",
          },
        ],
      }}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "https://www.vitalscore.ai/favicon.ico",
        },
      ]}
      additionalMetaTags={[
        {
          property: "article:published_time",
          content: publishedTime ?? "2024-09-01T00:00:00Z", // Replace with the published time in ISO format
        },
        {
          property: "article:modified_time",
          content: modifiedTime ?? "2024-09-01T00:00:00Z", // Replace with the modified time in ISO format
        },
      ]}
    />
  );
};

export default SEO;
